<template>
  <el-dialog
    v-loading="loading"
    top="10vh"
    visible
    append-to-body
    :before-close="close"
    :show-close="false"
    width="640px"
    custom-class="rounded"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 mb-2 border-bottom">
        <div class="d-flex flex-column">
          <h2 class="text-typography-primary">
            {{ $t('payment.matchPaymentDisparityModal.title') }}
          </h2>
          <div class="d-flex align-items-center gap-2">
            <p>{{ payment.supplierName }}</p>
            <DotIcon />
            <p>{{ formatDate(payment.receiptDate) }}</p>
            <DotIcon />
            <p>
              {{ $t(`document.exports.schema.type.fullName.${payment.document.type}`) }}
              {{ payment.document.documentNumber }}
            </p>
            <DotIcon />
            <p>{{ formatMoney(payment.amount) }}</p>
          </div>
        </div>
        <Button type="text" class="p-0 text-typography-primary actions-btn" @click="close">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="h-100">
      <div class="overflow-auto h-100">
        <Table
          :columns="columns"
          :data="tableData"
          :row-selection.sync="rowSelection"
          row-click-selection-toggle
          border
          rounded
          hover
          class="mb-4"
          small
        >
          <template #cell-billingDate="{ rowData: { billingDate } }">
            <p>
              {{ formatDate(billingDate) }}
            </p>
          </template>
          <template #cell-document="{ rowData: { document } }">
            <Button type="link" class="fs-normal" @click.stop="selectedDocumentId = document.id">
              <p>{{ $t(`document.exports.schema.type.fullName.${document.type}`) }} {{ document.documentNumber }}</p>
            </Button>
          </template>
          <template #cell-total="{ rowData: { total } }">
            <p>{{ formatMoney(total) }}</p>
          </template>
          <template #cell-totalUnpaid="{ rowData: { totalUnpaid } }">
            <p>{{ formatMoney(totalUnpaid) }}</p>
          </template>
        </Table>
      </div>
      <DocumentModal
        v-if="selectedDocumentId"
        visible
        :document-id="selectedDocumentId"
        @close="selectedDocumentId = null"
      />
    </div>
    <template #footer>
      <el-pagination
        v-if="unpaidBillings.length > PAGE_LIMIT"
        class="d-flex justify-content-end px-0 pb-4"
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="PAGE_LIMIT"
        :page-count="Math.ceil(unpaidBillings.length / PAGE_LIMIT)"
      />
      <div class="d-flex justify-content-between mb-4">
        <span> </span>
        <div>
          <p>{{ translate('totalAmountLeft') }}</p>
          <h2 v-if="!loading" :class="{ 'text-danger': amountLeftAfterUpdate < 0 }">
            {{ formatMoney(amountLeftAfterUpdate) }}
          </h2>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="d-flex align-items-end">
          <Button type="secondary" @click="close">
            {{ $t('commons.cancel') }}
          </Button>
          <Button :disabled="amountLeftAfterUpdate !== 0" @click="submit">
            {{ translate('createPaymentEvent') }}
          </Button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { reject, isNil, omit } from 'ramda';
import Big from 'big.js';

import { Button, Table } from '@/modules/core';
import { CloseIcon, DotIcon } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import { useBusinessById } from '@/modules/business/compositions/business';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { useUnpaidBillings, useBillings, useCreatePayment, usePayment } from '../../compositions';
import { formatDate } from '../../tools/formatters';

const PAGE_LIMIT = 20;

const TABLE_HEADER = {
  BILLING_DATE: 'billingDate',
  DOCUMENT: 'document',
  TOTAL: 'total',
  TOTAL_UNPAID: 'totalUnpaid',
};

export default {
  components: { DocumentModal, Button, Table, CloseIcon, DotIcon },
  props: {
    payment: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const { business: customer } = useBusinessById(
      computed(() => ({ businessId: props.paymentDifferences[0].customerId }))
    );
    const { formatToCurrency } = useCurrency();

    const currentPage = ref(1);

    const { payment: fullPaymentDetails, loading: paymentLoading } = usePayment(
      computed(() => ({
        id: props.payment.id,
      }))
    );

    const { unpaidBillings, loading: unpaidBillingsLoading } = useUnpaidBillings(
      computed(() => ({ businessId: customer.value.id, supplierId: fullPaymentDetails.value.supplierId }))
    );
    const sortedUnpaidBillings = computed(() =>
      [...unpaidBillings.value].sort((a, b) => new Date(b.date) - new Date(a.date))
    );

    const paginatedUnpaidBillings = computed(() =>
      sortedUnpaidBillings.value.slice((currentPage.value - 1) * PAGE_LIMIT, currentPage.value * PAGE_LIMIT)
    );

    const { billings, loading: billingsLoading } = useBillings(
      computed(() => ({
        ids: paginatedUnpaidBillings.value.map(({ billingId }) => billingId),
        businessId: customer.value.id,
      }))
    );

    const { createPayment, loading: creatingPayment, onDone, onError } = useCreatePayment();

    onError(() => $message.error($i18n.t('payment.matchPaymentDisparityModal.messages.createPaymentError')));

    onDone(async () => {
      $message.success($i18n.t('payment.matchPaymentDisparityModal.messages.createPaymentDone'));
      emit('close');
    });

    const loading = computed(
      () => unpaidBillingsLoading.value || billingsLoading.value || creatingPayment.value || paymentLoading.value
    );

    const paginatedUnpaidBillingsWithDocuments = computed(() =>
      !billings.value.length
        ? []
        : paginatedUnpaidBillings.value.map((unpaidBilling) => {
            const relevantBilling = billings.value.find((billing) => billing.id === unpaidBilling.billingId);

            return {
              ...unpaidBilling,
              document: relevantBilling?.eventReferences?.[0]?.document,
            };
          })
    );

    const selectedBillingIds = ref([]);
    const rowSelection = computed({
      get: () =>
        paginatedUnpaidBillingsWithDocuments.value
          .filter((unpaidBilling) => selectedBillingIds.value.includes(unpaidBilling.billingId))
          .map((unpaidBilling) => paginatedUnpaidBillingsWithDocuments.value.indexOf(unpaidBilling)),
      set: (indices) => {
        selectedBillingIds.value = selectedBillingIds.value.filter((billingId) =>
          paginatedUnpaidBillingsWithDocuments.value.every((unpaidBilling) => unpaidBilling.billingId !== billingId)
        );
        const selectedUnpaidBillings = paginatedUnpaidBillingsWithDocuments.value
          .filter((unpaidBilling, idx) => indices.includes(idx))
          .map(({ billingId }) => billingId);
        selectedBillingIds.value = [...selectedBillingIds.value, ...selectedUnpaidBillings];
      },
    });

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      createPayment,
      formatDate,
      formatMoney,
      PAGE_LIMIT,
      loading,
      unpaidBillings: sortedUnpaidBillings,
      currentPage,
      rowSelection,
      selectedBillingIds,
      selectedDocumentId: ref(null),
      paginatedUnpaidBillingsWithDocuments,
      customer,
      fullPaymentDetails,
    };
  },
  computed: {
    selectedAmount() {
      return this.unpaidBillings
        .filter(({ billingId }) => this.selectedBillingIds.includes(billingId))
        .reduce((sum, { unpaidAmount }) => sum + unpaidAmount, 0);
    },
    amountLeftAfterUpdate() {
      return new Big(this.payment.amount).minus(this.selectedAmount).toNumber();
    },
    columns() {
      return [
        {
          header: this.translate('table.headers.billingDate'),
          key: TABLE_HEADER.BILLING_DATE,
          width: '90px',
        },
        {
          header: this.translate('table.headers.document'),
          key: TABLE_HEADER.DOCUMENT,
          width: '120px',
        },
        {
          header: this.translate('table.headers.total'),
          key: TABLE_HEADER.TOTAL,
          width: '90px',
        },
        {
          header: this.translate('table.headers.totalUnpaid'),
          key: TABLE_HEADER.TOTAL_UNPAID,
          width: '90px',
        },
      ];
    },
    tableData() {
      return this.paginatedUnpaidBillingsWithDocuments.map(({ date, totalAmount, unpaidAmount, document }) => ({
        [TABLE_HEADER.BILLING_DATE]: date,
        [TABLE_HEADER.DOCUMENT]: document,
        [TABLE_HEADER.TOTAL]: totalAmount,
        [TABLE_HEADER.TOTAL_UNPAID]: unpaidAmount,
      }));
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    translate(key) {
      return this.$t(`payment.matchPaymentDisparityModal.${key}`);
    },
    async submit() {
      const confirm = await this.$confirm(this.$i18n.t('commons.areYouSure'), { type: 'warning' })
        .then(() => true)
        .catch(() => false);
      if (!confirm) return;

      const relevantBillings = this.unpaidBillings.filter(({ billingId }) =>
        this.selectedBillingIds.includes(billingId)
      );

      await this.createPayment({
        createParams: reject(isNil)({
          type: 'documentation',
          supplierId: this.fullPaymentDetails.supplierId,
          businessId: this.customer.id,
          customerId: this.fullPaymentDetails.customerId,
          amount: this.fullPaymentDetails.amount,
          date: this.fullPaymentDetails.date,
          eventReferences: this.fullPaymentDetails.eventReferences.map((ref) =>
            reject(isNil)(omit(['__typename'], ref))
          ),
          transactions: this.fullPaymentDetails.transactions.map((transaction) =>
            reject(isNil)(omit(['id', '__typename'], transaction))
          ),
          billingLinks: [...relevantBillings.map(({ billingId, totalAmount }) => ({ billingId, amount: totalAmount }))],
        }),
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  overflow-y: hidden;
  padding: 1em;
}

.actions-btn {
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}
</style>
