<template>
  <el-dialog
    v-loading="loading"
    top="10vh"
    visible
    append-to-body
    :before-close="close"
    :show-close="false"
    custom-class="rounded match-payment-modal"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center p-4 mb-2 border-bottom">
        <h2 class="text-typography-primary">
          {{ $t('payment.matchPaymentModal.header', { date: formatDate(payment.date) }) }}
        </h2>
        <Button type="text" class="p-0 text-typography-primary actions-btn" @click="close">
          <CloseIcon />
        </Button>
      </div>
    </template>
    <div class="h-100">
      <div class="overflow-auto h-100">
        <Table
          :columns="columns"
          :data="tableData"
          :row-selection.sync="rowSelection"
          row-click-selection-toggle
          show-index
          border
          rounded
          hover
          class="mb-4"
          small
        >
          <template #cell-index="{ rowIndex }">
            {{ rowIndex + 1 + PAGE_LIMIT * (currentPage - 1) }}
          </template>
          <template #cell-billingDate="{ rowData: { billingDate } }">
            <p>
              {{ formatDate(billingDate) }}
            </p>
          </template>
          <template
            #cell-billing="{
              rowData: {
                billing: { documentType, documentNumber, documentId },
              },
            }"
          >
            <Button type="link" class="fs-normal" @click.stop="selectedDocumentId = documentId">
              <p>{{ $t(`document.exports.schema.type.fullName.${documentType}`) }} {{ documentNumber }}</p>
            </Button>
          </template>
          <template #cell-total="{ rowData: { total } }">
            <p>{{ formatMoney(total) }}</p>
          </template>
          <template #cell-status>
            <Tag>
              {{ translate('table.notPaidYet') }}
            </Tag>
          </template>
        </Table>
      </div>
      <DocumentModal
        v-if="selectedDocumentId"
        visible
        :document-id="selectedDocumentId"
        @close="selectedDocumentId = null"
      />
    </div>
    <template #footer>
      <el-pagination
        v-if="unpaidBillings.length > PAGE_LIMIT"
        class="d-flex justify-content-end px-0 pb-4"
        small
        layout="prev, pager, next, jumper"
        background
        :current-page.sync="currentPage"
        :page-size="PAGE_LIMIT"
        :page-count="Math.ceil(unpaidBillings.length / PAGE_LIMIT)"
      />
      <div class="d-flex justify-content-between mb-4">
        <p v-if="selectedBillingIds.length">
          {{
            $tc('payment.matchPaymentModal.selected', selectedBillingIds.length, {
              amount: formatMoney(selectedAmount),
              count: selectedBillingIds.length,
            })
          }}
        </p>
        <span v-else> </span>
        <div>
          <p>{{ translate('totalPaymentAmountRemaining') }}</p>
          <h2 v-if="!loading" :class="{ 'text-danger': amountLeftAfterUpdate < 0 }">
            {{ formatMoney(amountLeftAfterUpdate) }}
          </h2>
        </div>
      </div>
      <div class="d-flex justify-content-end">
        <div class="d-flex align-items-end">
          <Button type="secondary" @click="close">
            {{ $t('commons.cancel') }}
          </Button>
          <Button :disabled="amountLeftAfterUpdate < 0 || !selectedBillingIds.length" @click="submit">
            {{ $t('commons.save') }}
          </Button>
        </div>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { computed, ref, getCurrentInstance } from 'vue';
import { omit, reject, isNil } from 'ramda';
import Big from 'big.js';

import { Button, Table, Tag } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
import { DocumentModal } from '@/modules/documentModal';
import { useCurrency } from '@/modules/core/compositions/money-currency';

import { useUnpaidBillings, useBillings, usePaymentPatch } from '../../compositions';
import { formatDate } from '../../tools/formatters';

const PAGE_LIMIT = 20;

const TABLE_HEADER = {
  BILLING_DATE: 'billingDate',
  BILLING: 'billing',
  TOTAL: 'total',
  STATUS: 'status',
};

export default {
  components: { DocumentModal, Button, Table, Tag, CloseIcon },
  props: {
    payment: { type: Object, required: true },
    supplier: { type: Object, required: true },
  },
  setup(props, { emit }) {
    const { $message, $i18n } = getCurrentInstance().proxy;
    const businessId = computed(() => props.payment.businessId);
    const { formatToCurrency } = useCurrency();

    const currentPage = ref(1);

    const { unpaidBillings, loading: unpaidBillingsLoading } = useUnpaidBillings(
      computed(() => ({ businessId: businessId.value, supplierId: props.supplier.id }))
    );
    const sortedUnpaidBillings = computed(() =>
      [...unpaidBillings.value].sort((a, b) => new Date(b.date) - new Date(a.date))
    );

    const paginatedUnpaidBillings = computed(() =>
      sortedUnpaidBillings.value.slice((currentPage.value - 1) * PAGE_LIMIT, currentPage.value * PAGE_LIMIT)
    );

    const { billings, loading: billingsLoading } = useBillings(
      computed(() => ({
        ids: paginatedUnpaidBillings.value.map(({ billingId }) => billingId),
        businessId: businessId.value,
      }))
    );

    const { patchPayment, loading: updatingPayment, onDone, onError } = usePaymentPatch();

    onError(() => $message.error($i18n.t('payment.matchPaymentModal.messages.updatePaymentError')));

    onDone(async () => {
      $message.success($i18n.t('payment.matchPaymentModal.messages.updatePaymentDone'));
      emit('close');
    });

    const loading = computed(() => unpaidBillingsLoading.value || billingsLoading.value || updatingPayment.value);

    const paginatedUnpaidBillingsWithDocuments = computed(() =>
      !billings.value.length
        ? []
        : paginatedUnpaidBillings.value.map((unpaidBilling) => {
            const relevantBilling = billings.value.find((billing) => billing.id === unpaidBilling.billingId);

            return {
              ...unpaidBilling,
              document: relevantBilling?.eventReferences?.[0]?.document,
            };
          })
    );

    const selectedBillingIds = ref([]);
    const rowSelection = computed({
      get: () =>
        paginatedUnpaidBillingsWithDocuments.value
          .filter((unpaidBilling) => selectedBillingIds.value.includes(unpaidBilling.billingId))
          .map((unpaidBilling) => paginatedUnpaidBillingsWithDocuments.value.indexOf(unpaidBilling)),
      set: (indices) => {
        selectedBillingIds.value = selectedBillingIds.value.filter((billingId) =>
          paginatedUnpaidBillingsWithDocuments.value.every((unpaidBilling) => unpaidBilling.billingId !== billingId)
        );
        const selectedUnpaidBillings = paginatedUnpaidBillingsWithDocuments.value
          .filter((unpaidBilling, idx) => indices.includes(idx))
          .map(({ billingId }) => billingId);
        selectedBillingIds.value = [...selectedBillingIds.value, ...selectedUnpaidBillings];
      },
    });

    const formatMoney = (value) => {
      if (typeof value === 'number' && !Number.isNaN(value)) {
        const number = Number(value.toFixed(2));
        const options = Number.isInteger(number) ? { maximumFractionDigits: 0 } : {};
        return formatToCurrency(value, options);
      }
      return '-';
    };

    return {
      patchPayment,
      formatDate,
      formatMoney,
      PAGE_LIMIT,
      loading,
      unpaidBillings: sortedUnpaidBillings,
      currentPage,
      rowSelection,
      selectedBillingIds,
      selectedDocumentId: ref(null),
      paginatedUnpaidBillingsWithDocuments,
    };
  },
  computed: {
    selectedAmount() {
      return this.unpaidBillings
        .filter(({ billingId }) => this.selectedBillingIds.includes(billingId))
        .reduce((sum, { totalAmount }) => sum + totalAmount, 0);
    },
    amountLeftAfterUpdate() {
      return new Big(this.payment.paidAmount).minus(this.payment.billedAmount).minus(this.selectedAmount).toNumber();
    },
    columns() {
      return [
        {
          header: this.translate('table.headers.billingDate'),
          key: TABLE_HEADER.BILLING_DATE,
        },
        {
          header: this.translate('table.headers.billings'),
          key: TABLE_HEADER.BILLING,
        },
        {
          header: this.translate('table.headers.total'),
          key: TABLE_HEADER.TOTAL,
        },
        {
          header: this.translate('table.headers.status'),
          key: TABLE_HEADER.STATUS,
        },
      ];
    },
    tableData() {
      return this.paginatedUnpaidBillingsWithDocuments.map(({ date, totalAmount, document }) => ({
        [TABLE_HEADER.BILLING_DATE]: date,
        [TABLE_HEADER.BILLING]: {
          documentNumber: document?.documentNumber,
          documentId: document?.id,
          documentType: document?.type,
        },
        [TABLE_HEADER.TOTAL]: totalAmount,
      }));
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
    translate(key) {
      return this.$t(`payment.matchPaymentModal.${key}`);
    },
    async submit() {
      const confirm = await this.$confirm(this.$i18n.t('commons.areYouSure'), { type: 'warning' })
        .then(() => true)
        .catch(() => false);
      if (!confirm) return;

      const relevantBillings = this.unpaidBillings.filter(({ billingId }) =>
        this.selectedBillingIds.includes(billingId)
      );

      await this.patchPayment({
        id: this.payment.paymentId,
        patchParams: {
          billingLinks: [
            ...this.payment.billingLinks.map((b) => reject(isNil)(omit(['__typename'], b))),
            ...relevantBillings.map(({ billingId, totalAmount }) => ({ billingId, amount: totalAmount })),
          ],
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .match-payment-modal {
  display: grid;
  grid-template-rows: 60px 1fr 160px;
  width: 55.8125rem;
  height: 80vh;
}

::v-deep .el-dialog__header {
  padding: 0;
}

::v-deep .el-dialog__body {
  overflow-y: hidden;
  padding: 1em;
}

.actions-btn {
  &:hover {
    background: $secondary;
  }
  &.active {
    visibility: visible;
  }
}
</style>
