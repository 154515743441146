<template>
  <el-dialog
    :show-close="false"
    visible
    append-to-body
    width="30vw"
    custom-class="rounded execution-date-modal"
    @close="$emit('close')"
  >
    <template #title>
      <div class="d-flex justify-content-between align-items-center border-bottom p-4">
        <span>
          <h2>
            {{ $t('payment.paymentOperationModal.table.headerActions.paymentExecutionDateModal') }}
          </h2>
        </span>
        <span>
          <Button type="icon" class="p-0" @click="$emit('close')">
            <CloseIcon />
          </Button>
        </span>
      </div>
    </template>
    <div class="p-4">
      <el-date-picker
        v-model="pickedDate"
        size="small"
        type="date"
        format="dd.MM.yyyy"
        :clearable="false"
        :editable="false"
        :picker-options="datePickerOptions()"
        :placeholder="`${$t('commons.select')}...`"
      />
    </div>
    <template #footer>
      <div class="p-4">
        <Button type="secondary" @click="$emit('close')">{{ $t('commons.cancel') }}</Button>
        <Button @click="$emit('submit', pickedDate)">
          {{ $t('commons.apply') }}
        </Button>
      </div>
    </template>
  </el-dialog>
</template>

<script>
import { Button } from '@/modules/core';
import { CloseIcon } from '@/assets/icons';
export default {
  components: { Button, CloseIcon },
  props: {
    datePickerOptions: { type: Function, required: true },
  },
  data() {
    return {
      pickedDate: new Date(),
    };
  },
};
</script>

<style lang="scss" scoped>
::v-deep .execution-date-modal {
  .el-dialog__header,
  .el-dialog__body,
  .el-dialog__footer {
    padding: 0;
  }
}
</style>
